@import "settings/base-variables";

// Overrides 
// - - - - - - - - - - - - - - - - - - - - - - - - -

// menu hover colors for accessibility
$menu-item-color-active: scale-color($uoitlightblue, $lightness: -10%);
// $menu-item-background-active: $uoitgreymedium;

// home modules max height (accommodates Expert File profiles)
// $max_module_height: 390px;

@import "global";